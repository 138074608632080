import * as React from "react";
import Layout from "../../components/layout";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

// markup
const PolicyIfPage = () => {
  return (
    <Layout
      title="プライバシーポリシー | CO Record &amp; journal"
      policyStyle={true}
      showHeader={false}
      isTop={false}
    >
      <main id="id-main" className="policy-body-if">
        <section>
          <div className="contents">
            <div className="text-box">
              <h3>CO Record &amp; Journal プライバシーポリシー</h3>
              <p>
                帝人株式会社（東京都千代田区霞が関三丁目2番1号霞が関コモンゲート西館　　代表取締役社長執行役員
                内川哲茂）（以下「当社」といいます）が提供する、CO Record &amp;
                Journal（以下「本サービス」といいます）の利用にあたっては、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます）を注意深くお読みいただき、これに同意のうえ、ご利用ください。同意いただけない場合には、ご利用をお控えください。
              </p>
              <ol>
                <li>
                  <p>
                    当社は、ユーザーの個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます）を遵守すると共に、本プライバシーポリシーに従い、適切な取扱い及び保護に努めます。
                  </p>
                </li>
                <li>
                  <p>
                    本プライバシーポリシーにおいて、別途定めのない限り、個人情報、個人データ、要配慮個人情報及び匿名加工情報に関しては、個人情報保護法の定義に従うものとし、その他の定義については、CO
                    Record &amp;
                    Journal利用規約（以下「本利用規約」といいます）の定義に従うものとします。
                  </p>
                </li>
                <li>
                  <p>
                    当社は、本サービスの提供にあたって、以下に掲げる個人情報を含むユーザーに関する情報を収集し、以下の利用目的に従って利用します。
                  </p>
                  <ol>
                    <li>
                      アカウント登録における収集及び当社からの求めに応じた情報の提供
                      <br />
                      A)
                      収集情報：ユーザーが本アプリを通じて届け出た住所、氏名、性別、生年月日、メールアドレス、ニックネーム等のユーザー情報（以下「ユーザー情報」といいます）
                      <br />
                      B) 利用目的：以下のとおり
                      <blockquote>
                        <p>
                          ①　ユーザー登録可否の審査及びユーザー情報の管理等を行うため
                          <br />
                          ②　本サービスを提供するため
                          <br />
                          ③　本サービスの提供にあたって、提携パートナーに対して、ユーザーに代わってユーザー情報を提供するため
                          <br />
                          ④　本サービスに関する情報の提供、他のサービスの案内等のメール送付及びお問い合わせへの対応のため
                          <br />
                          ⑤　本利用規約に基づく当社の義務を履行するため
                          <br />
                          ⑥　本サービスの運営に関する事務連絡を行うため
                          <br />
                          ⑦　本サービスに関連するキャンペーン及びアンケートを実施するため
                          <br />
                          ⑧　商品又は資料の発送、電子メールの配信等の本サービスに関連する商品、サービス又は情報の提供を行うため
                          <br />
                          ⑨　本利用規約に違反する行為に対する対応のため
                          <br />
                          ⑩　本サービスの改善及び本サービスに類似のサービスの企画や開発に役立てるため
                          <br />
                          ⑪　当社が本サービス若しくは本サービスに類似の又はそのマーケティングに向けた分析を行うため
                          <br />
                          ⑫　その他上記利用目的に付随する目的のため
                        </p>
                      </blockquote>
                    </li>
                    <li>
                      お問い合わせフォームにおける収集
                      <br />
                      A)
                      収集情報：ユーザーID、ユーザー名、メールアドレス、問い合わせ本文
                      <br />
                      B)
                      利用目的：お問い合わせ等への対応のため、並びに本サービスの改善及び本サービスに類似のサービスの開発に役立てるため
                    </li>
                    <li>
                      本サービスの有償サービスに係る利用料金の支払いにおける収集
                      <br />
                      A)
                      収集情報：クレジットカード番号等の支払情報、口座の認証情報、請求先住所等の連絡先情報等（支払決済は第三者である決済サービス会社が行うため、当社は当該収集情報を直接入手しませんが、必要に応じて決済サービス会社から事後的に当該収集情報を取得する場合があります。）
                      <br />
                      B)
                      利用目的：有償サービスの利用料金の決済及びユーザーの信用情報照会のため
                    </li>
                    <li>
                      本サービス利用時における収集
                      <br />
                      A)
                      収集情報：端末情報（製品モデル、オペレーティングシステム、通信キャリア、情報端末の設定、IPアドレスその他の情報端末固有の識別子等の情報が含まれます。）、利用履歴（位置情報、時間、歩数、購入履歴、他のユーザーとのやりとり、故障情報等が含まれます。）
                      <br />
                      B) 利用目的：以下のとおり
                      <blockquote>
                        <p>
                          ①　本サービスを提供するため
                          <br />
                          ②　本サービスの提供にあたって、提携パートナーに対して、ユーザーに代わって情報を提供するため
                          <br />
                          ③　ユーザーに適した広告配信、表示を行うため
                          <br />
                          ④　広告効果測定のため
                          <br />
                          ⑤　本利用規約に基づく当社の義務を履行するため
                          <br />
                          ⑥　本サービスに関する事務連絡を行うため
                          <br />
                          ⑦　本サービスの改善及び本サービスに類似のサービスの開発に役立てるため
                          <br />
                          ⑧　その他上記利用目的に付随する目的のため
                        </p>
                      </blockquote>
                    </li>
                    <li>
                      提携サービスとの連携による取得 A)
                      収集情報：ユーザーが提携パートナーに開示を認めた情報 B)
                      利用目的：提携サービスとの連携のため
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    当社は、以下に定める場合を除き、あらかじめユーザーの同意を得ないで、前条に定める利用目的の達成に必要な範囲を超えて、ユーザーの個人情報を取り扱いません。
                  </p>
                  <ol>
                    <li>法令に基づく場合</li>
                    <li>
                      法令で本人の同意なく開示・提供することが許容される場合
                    </li>
                    <li>
                      人の生命、身体又は財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難である場合
                    </li>
                    <li>
                      公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難である場合
                    </li>
                    <li>
                      国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    当社は、適正にユーザーの個人情報を取得し、偽りその他不正の手段によりユーザーの個人情報を取得しません。
                  </p>
                </li>
                <li>
                  <p>
                    当社は、ユーザーの個人情報の安全管理のために以下に定める措置を講じます。
                  </p>
                  <ol>
                    <li>（基本方針）</li>
                  </ol>
                  <blockquote>
                    <p>
                      個人情報の適正な取り扱いの確保について組織として取り組むために、基本方針を策定しています。
                      （「
                      <a
                        target="_blank"
                        href="https://www.teijin.co.jp/privacy/"
                      >
                        帝人グループのプライバシーポリシー
                      </a>
                      」参照）
                    </p>
                  </blockquote>
                  <ol start="2">
                    <li>(個人情報の取扱いに係る規律の整備)</li>
                  </ol>
                  <blockquote>
                    <p>
                      個人情報の取扱方法、責任者・担当者およびその任務等について取扱規程を策定しています。
                    </p>
                  </blockquote>
                  <ol start="3">
                    <li>（組織的安全管理措置）</li>
                  </ol>
                  <blockquote>
                    <p>
                      個人情報管理の責任者として「個人情報保護責任者」を任命するとともに、個人データの安全管理に関する従業員の責任と権限を明確に規定しています。
                      従業員（派遣社員・アルバイトの方々を含みます。）を監督するとともに、法令や取扱規程に違反している事実、またはかかる違反の兆候を把握した場合の責任者への報告連絡体制を整備しています。
                      安全管理に関する内部規程等を定め、それらを従業員に遵守させるとともに、その遵守の状況について適切な監査を実施します。
                    </p>
                  </blockquote>
                  <ol start="4">
                    <li>（人的安全管理措置）</li>
                  </ol>
                  <blockquote>
                    <p>
                      従業員に対して個人データの安全管理に関する定期的な教育研修を実施します。
                    </p>
                  </blockquote>
                  <ol start="5">
                    <li>（技術的・物理的安全管理措置）</li>
                  </ol>
                  <blockquote>
                    <p>
                      保有する個人データに対し、以下のアクセス管理を実施しています。
                      ・アクセス権限者の限定 ・アクセス状況の取得、保存、監視
                      ・パスワードの定期的変更 ・その他、入退室管理等
                      個人情報を取り扱う機器、電子媒体および書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人情報が判明しないよう措置を実施しています。
                      個人情報を取り扱う情報システムを外部からの不正アクセスまたは不正ソフトウェアから保護する仕組みを導入しています。
                    </p>
                  </blockquote>
                  <ol start="6">
                    <li>（外的環境の把握）</li>
                  </ol>
                  <blockquote>
                    <p>
                      外国において個人情報を取り扱う場合には、法令に従い、当該国の個人情報保護法制等を把握し、安全管理のために必要かつ適切な措置を実施します。
                    </p>
                  </blockquote>
                </li>
                <li>
                  <p>
                    当社は、次の範囲で個人データを第三者に提供することがあります。
                  </p>
                  <ol>
                    <li>提供先（第三者）：レイ・フロンティア株式会社</li>
                    <li>
                      提供される場面：本サービス上の第９条に定める情報収集モジュールを介して提供
                    </li>
                    <li>
                      利用目的：本サービスに技術又はサービスを提供している提携パートナーの製品・サービスの品質改善のため
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    当社は、第４条(1)乃至(5)及び前条のいずれかに該当する場合を除き、あらかじめユーザーの同意を得ないで、ユーザーの個人データを第三者に提供しません。
                  </p>
                </li>
                <li>
                  <p>
                    当社は、本サービスの利用状況を把握するため（リターゲティング広告配信の目的を含みます。）及び提供する本サービスのサービス向上のため、Identification
                    for
                    Advertisers（以下「IDFA」といいます。）、AdvertisingID(以下「AdID」といいます。」)、Cookie又は以下の解析ツール、情報収集モジュールを利用して、本サービスの利用情報（端末情報等及びアプリ起動ログ、ブラウザ閲覧ログを含みますが、これらに限られません。）を収集します（なお、IDFA、AdID又はCookie及び情報収集モジュール等数種を併用する場合、並びに情報収集モジュール等がIDFA、AdID又はCookieを使用する場合があります。）。当社が本サービスにて導入するIDFA、AdID及びこれら情報収集モジュール等については、以下をご参照ください。当社は、IDFA、AdID、Cookie、及び情報収集モジュール等の利用に起因して生じた損害について責任を負いかねます。
                  </p>
                  <ol>
                    <li>
                      <p>
                        情報収集モジュールの名称／提供者等
                        <br />
                        A) Google Analytics／Google,Inc.　
                      </p>
                      <blockquote>
                        <p>
                          【概要】
                          <br />
                          Google
                          AnalyticsはCookie機能（注）を利用し、ユーザーの行動情報を取得するほか、Googleの広告ネットワークなどから取得する年齢、性別、興味・関心といったユーザー属性データも併せて計測します。詳細については、「Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用」
                          <a
                            target="_blank"
                            href="https://policies.google.com/technologies/partner-sites?hl=ja"
                          >
                            （https://policies.google.com/technologies/partner-sites?hl=ja）
                          </a>
                          をご覧ください。
                          <br />
                          Google
                          Analyticsの無効設定は、Googleから提供されている「Google
                          Analyticsオプトアウト　アドオン」
                          <a
                            target="_blank"
                            href="https://tools.google.com/dlpage/gaoptout/"
                          >
                            （https://tools.google.com/dlpage/gaoptout/）
                          </a>
                          の適用により、実施することができます。
                          <br />
                          上記以外の方法として、ユーザーがブラウザーの設定等により、Cookie機能を停止することもできますが、その場合は一部のサービスがご利用できなくなる場合がございますので、予めご了承ください。
                        </p>
                      </blockquote>
                      <blockquote>
                        <p>
                          （注）Cookieとは、ユーザーがウェブサイトに訪問した際、ブラウザーを通してユーザーのコンピューターに一時的にデータを記録する仕組みのことです。クッキーには、ユーザーの端末に関する情報やサイトへの訪問回数といった情報が記録され、サイトへの訪問者としてのユーザーの識別として利用されます。
                          <br />
                          【関連規約】
                          <br />
                          ・Google Analytics利用規約
                          <br />
                          <a
                            target="_blank"
                            href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                          >
                            https://marketingplatform.google.com/about/analytics/terms/jp/
                          </a>
                          <br />
                          ・Google プライバシー ポリシー
                          <br />
                          <a
                            target="_blank"
                            href="https://policies.google.com/privacy"
                          >
                            https://policies.google.com/privacy
                          </a>
                        </p>
                      </blockquote>
                      <p>B) Firebase Crashlytics／Google,Inc.　</p>
                      <blockquote>
                        <p>
                          【概要】
                          <br />
                          Firebase
                          Crashlyticsは、ユーザーに利用されている端末の情報及びクラッシュ状況に関する情報をGoogleが設置するサーバーに送信し、Googleは当該情報を当社に送信します。Googleは、Googleの利用規約及びプライバシーポリシーに基づき、利用されている端末の情報及びクラッシュ情報を管理します。
                          <br />
                          【関連規約】
                          <br />
                          ・Crashlytics利用規約
                          <br />
                          <a
                            target="_blank"
                            href="https://firebase.google.com/terms"
                          >
                            https://firebase.google.com/terms（英語）
                          </a>
                          <br />
                          ・Crashlytics プライバシー ポリシー
                          <br />
                          <a
                            target="_blank"
                            href="https://policies.google.com/privacy"
                          >
                            https://policies.google.com/privacy
                          </a>
                        </p>
                      </blockquote>
                      <p>C) SilentLog SDK／レイ・フロンティア株式会社　</p>
                      <blockquote>
                        <p>
                          【概要】
                          <br />
                          レイ・フロンティア株式会社のプライバシーポリシーに基づき、個人を特定しない形で利用されている端末の位置情報及びセンサー情報を取得します。
                          <br />
                          【関連規約】
                          <br />
                          ・レイ・フロンティア プライバシーポリシー
                          <br />
                          <a
                            target="_blank"
                            href="https://www.rei-frontier.jp/privacypolicy/"
                          >
                            https://www.rei-frontier.jp/privacypolicy/
                          </a>
                        </p>
                      </blockquote>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    当社は、匿名加工情報（法令に定める措置を講じて特定の個人を識別することができないように個人情報を加工して得られる個人に関する情報であって、当該個人情報を復元することができないようにしたもの）を作成又は第三者に提供する場合には、以下の対応を行います。
                  </p>
                  <ol>
                    <li>
                      匿名加工情報を作成する場合
                      <br />
                      A) 法令で定める基準に従って、適正な加工を施すこと
                      <br />
                      B)
                      法令で定める基準に従って、削除した情報や加工の方法に関する情報の漏洩を防止するために安全管理措置を講じること
                      <br />
                      C)
                      作成した匿名加工情報に含まれる個人に関する情報の項目を公表すること
                      <br />
                      D)
                      作成の元となった個人情報の本人を識別するための行為をしないこと
                      <br />
                      E)
                      安全管理措置及び苦情処理その他の匿名加工情報の適正な取扱いを確保するために必要な措置を自ら講じるとともに、当該措置の内容を公表するよう努めること
                    </li>
                    <li>
                      匿名加工情報を第三者に提供する場合
                      <br />
                      A)
                      当社は、匿名加工情報を第三者に提供する場合には、提供しようとする匿名加工情報に含まれる個人に関する情報の項目と提供の方法を公表するとともに、提供先となる第三者に対して、提供する情報が匿名加工情報であることを明示すること
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    前条に基づき、当社は、当社が保有する以下の情報について、特定の個人を識別すること及び作成に用いる個人情報を復元することができないよう適切な保護措置を講じたうえで匿名加工情報として継続的に作成し、以下に従い匿名加工情報を第三者に提供することを公表します。
                  </p>
                  <ol>
                    <li>
                      匿名加工情報に含まれる項目：ユーザーの個人属性情報（性別、生年、身長、体重、腹囲）、本サービスの利用歴（位置情報、歩数、時間）
                    </li>
                    <li>
                      匿名加工情報の第三者提供
                      <br />
                      A)
                      目的：本サービスの提供及び運用、本サービス内容の改良及び向上等の目的並びに提携パートナーによる提携サービスの提供
                      <br />
                      B)
                      第三者に提供する匿名加工情報に含まれる項目：上記（１）の項目
                      <br />
                      C)
                      提供方法：データファイルとして暗号化し、セキュリティが確保された手段で提供
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    当社は、ユーザーから、個人情報保護法の定めに基づき個人データの開示、利用目的の通知又は第三者提供記録の開示を求められたときは、ユーザー本人からの請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。
                  </p>
                </li>
                <li>
                  <p>
                    当社は、ユーザーから、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます）を求められた場合には、ユーザー本人からの請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をユーザーに通知します（訂正等を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。
                  </p>
                </li>
                <li>
                  <p>
                    当社は、ユーザーから、ユーザーの個人情報について、第３条に定める利用目的の範囲を超えて取り扱われているという理由、偽りその他不正の手段により取得されたものであるという理由、当社において利用する必要がないものとなったという理由、要配慮個人情報が含まれる個人データの漏えい、滅失若しくは毀損（以下「漏えい等」といいます）が発生し若しくは発生したおそれがあるという理由、不正に利用されることにより財産的被害が生じるおそれがある個人データの漏えい等が発生し若しくは発生したおそれがあるという理由、不正の目的をもって行われたおそれがある個人データの漏えい等が発生し若しくは発生したおそれがあるという理由、又は個人データに係る本人の数が千人を超える漏えい等が発生し若しくは発生したおそれがあるという理由その他ユーザー本人の権利又は正当な利益が害されるおそれがあるという理由により、個人情報保護法の定めに基づきその利用、第三者提供の停止又はその消去（以下「利用停止等」といいます）を求められた場合において、その請求に理由があることが判明した場合には、ユーザー本人からの請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨をユーザーに通知します。但し、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。なお、ユーザーの個人情報の利用停止又は消去を行った場合、ユーザーの要望に沿ったサービスの提供等ができなくなることがございますので、あらかじめご了承ください。
                  </p>
                </li>
                <li>
                  <p>
                    ユーザー本人からの開示、訂正等又は利用停止等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、以下の窓口又は本サイトのフォーム等を通じて、当社までご連絡ください。
                  </p>

                  <p>問い合わせ窓口：帝人株式会社 個人情報相談窓口</p>
                  <p>
                    問い合わせフォーム：
                    <a
                      target="_blank"
                      href="https://inquiry.teijin.co.jp/form/contact_pi_jp.html"
                    >
                      こちら
                    </a>
                    からお問い合わせください
                  </p>
                </li>
              </ol>

              <ol start="16">
                <li>
                  当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。変更後の本プライバシーポリシーの効力発生日の1か月前までに、本プライバシーポリシーの内容を変更する旨および変更後の内容とその効力発生日を本サイト上に掲示し、又はユーザーに電子メールで通知します。変更後の本プライバシーポリシーの効力発生日以降にユーザーが本サービスを利用したときは、ユーザーは、本プライバシーポリシーの変更に同意したものとみなします。
                </li>
              </ol>
              <p>以上</p>
              <p>制定日：2022年７月７日</p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default PolicyIfPage;
